/* eslint-disable*/
import VueStoreService from './VueStoreService';
const GrouppingService = {
    async getGroupping(filter,store){
        var resp = await VueStoreService.get("groupping"+filter, store)
        return resp;
    },
    async saveGroupping(data,store){
        var resp = await VueStoreService.post("groupping",data,store)
        return resp;
    },
    async getGrouppingById(groupping_id,store){
        var resp = await VueStoreService.get("groupping/"+groupping_id,store)
        return resp;
    },
    async updateGroupping(groupping_id,data,store){
        var resp = await VueStoreService.post("groupping/"+groupping_id,data,store)
        return resp;
    },
    async deleteGroupping(groupping_id,store){
        var resp = await VueStoreService.delete("groupping/"+groupping_id,store)
        return resp;
    },
}

export default GrouppingService;